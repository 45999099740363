import React, { useState, useEffect } from 'react';
import axios from 'axios';

const MaticDataNew = () => {
  const [gasPrices, setGasPrices] = useState(null);

  useEffect(() => {
    const fetchGasPrices = async () => {
      try {
        const response = await axios.get('https://gasstation.polygon.technology/v2');
        setGasPrices(response.data);
      } catch (error) {
        console.error('Error fetching gas prices:', error);
      }
    };

    fetchGasPrices();
  }, []);

  if (!gasPrices) {
    return <div className='w-full flex items-center justify-center mt-12'><p className='text-white text-lg text-center'>Loading gas prices...</p></div>;
  }

  const { fast, standard, safeLow } = gasPrices;

  return (
    <div className='grid grid-cols-1 md:grid-cols-3 gap-6 mt-12'>
      <div className='bg-gray-800 rounded shadow-sm p-6 border border-gray-700'>
        <div className='flex justify-center items-start'>
          <div>
            <h2 className='text-2xl font-medium tracking-wider text-gray-100'>Fast</h2>
          </div>
        </div>

        <div className='flex justify-center items-end mt-4'>
          <div>
            <p className='text-6xl font-semibold text-yellow-600 tracking-wider mb-1'>{Math.round(fast.maxPriorityFee)}</p>
          </div>
        </div>
      </div>

      <div className='bg-gray-800 rounded-lg shadow-sm p-6 border border-gray-700'>
        <div className='flex justify-center items-start'>
          <div>
            <h2 className='text-2xl font-medium tracking-wider text-gray-100'>Standard</h2>
          </div>
        </div>
        <div className='flex justify-center items-end mt-4'>
          <div>
            <p className='text-6xl font-semibold text-blue-400 tracking-wider mb-1'>{Math.round(standard.maxPriorityFee)}</p>
          </div>
        </div>
      </div>

      <div className='bg-gray-800 rounded-lg shadow-sm p-6 border border-gray-700'>
        <div className='flex justify-center items-start'>
          <div>
            <h2 className='text-2xl font-medium tracking-wider text-gray-100'>Slow</h2>
          </div>
        </div>

        <div className='flex justify-center items-end mt-4'>
          <div>
            <p className='text-6xl font-semibold text-indigo-400 tracking-wider mb-1'>{Math.round(safeLow.maxPriorityFee)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaticDataNew;