import * as React from "react"

import Layout from "../components/layout/layout"
import MaticDataNew from "../components/sections/matic-data-new"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Polygon Matic Gas Price" />

    <div className="bg-gray-900">
      <main className='min-h-screen w-11/12 lg:w-10/12 xl:max-w-screen-xl py-12 mx-auto'>
        <div className='flex flex-col items-center justify-center border-b border-gray-800 pb-6'>
          <h1 className='text-4xl text-white text-center mb-3'><span className='font-bold'>MATIC </span>Gas</h1>
          <p className='text-center text-sm text-gray-100'>Polygon Matic Gas Prices in GWei</p>
        </div>

        <MaticDataNew />
      </main>
    </div>
  </Layout>
)

export default IndexPage
